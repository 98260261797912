<script setup>
import { defineProps, onMounted, onUpdated } from 'vue'
import { Gems } from '@/gems'
import { KNOLIA } from '@/knolia'

const props = defineProps({
    expandedCriterias: Object,
    isActivitiesJob: Boolean
})

function ToggleTasks(activity) {
    activity.showChildren = !activity.showChildren
    activity.show = !activity.show
}

function ToggleSkills(task) {
    task.showChildren = !task.showChildren
    task.show = !task.show
}

function SetTooltips() {
    const tasks = Array.from(document.querySelectorAll('.div-task'))
    const skills = Array.from(document.querySelectorAll('.div-skill'))
    const sendIcons = Array.from(document.querySelectorAll('.fa-check'))
    const cancelIcons = Array.from(document.querySelectorAll('.fa-xmark'))
    const showRequestIcons = Array.from(document.querySelectorAll('.fa-pencil-alt'))

    props.expandedCriterias.forEach((activity, ind) => {
        const activityDiv = document.getElementById(`div-activity-${ind}`)
        Gems.Tooltip(activityDiv, 'Atividade', 'tooltip')   
    })

    tasks.forEach(task => {
        Gems.Tooltip(task, 'Tarefa', 'tooltip')
    })

    skills.forEach(skill => {
        Gems.Tooltip(skill, 'Competência', 'tooltip')
    })

    sendIcons.forEach(icon => {
        Gems.Tooltip(icon, 'Gravar', 'tooltip')
    })

    cancelIcons.forEach(icon => {
        Gems.Tooltip(icon, 'Cancelar', 'tooltip')
    })

    showRequestIcons.forEach(icon => {
        Gems.Tooltip(icon, 'Pedido de formação', 'tooltip')
    })
}

async function SendTrainingRequest(id, elem) {
    const request = document.getElementById(id)

    KNOLIA.CallBackEnd('post', 'set-operations&get=send-training-request',
        {
            params: {
                request: request.value,
                elem
            }
        }
    );

    request.value = null
}

onMounted(() => {
    SetTooltips()
})

onUpdated(() => {
    SetTooltips()
})

</script>

<template>
    <div class="div-job-activities">
        <div class="div-job-activity-name">
            <slot name="title"></slot>
        </div>

        <div v-for="(activity,  ind) in props.expandedCriterias" :key="activity.id" class="row">
            <div :class="{ 'div-activity': props.isActivitiesJob, 'div-extra-job': !props.isActivitiesJob }">
                <div class="div-row-text" :id="`div-activity-${ind}`">
                    {{ activity.designation }}
                    <strong>|</strong>
                    <div>Gap [min.]: {{ activity.gap }}% [{{ activity.min_gap }}%]</div>
                    <i v-show="activity.children.length > 0" @click="ToggleTasks(activity)" class="fas cursor-pointer"
                        :class="{ 'fa-chevron-down': !activity.showChildren, 'fa-chevron-up': activity.showChildren }"></i>
                </div>
                <div class="div-training-requests">
                    <input :id="`training-activity-${activity.id}`" class="input-training-request" type="text"
                        maxlength="255" v-if="activity.showTrainingReqInput">
                    <i class="fa fa-check cursor-pointer" v-if="activity.showTrainingReqInput"
                        @click="SendTrainingRequest(`training-activity-${activity.id}`, activity)"></i>

                    <i class="fas fa-pencil-alt cursor-pointer show-icon" v-if="!activity.showTrainingReqInput"
                        @click="activity.showTrainingReqInput = !activity.showTrainingReqInput"></i>
                    <i class="fas fa-xmark cursor-pointer show-icon" v-if="activity.showTrainingReqInput"
                        @click="activity.showTrainingReqInput = !activity.showTrainingReqInput"></i>
                </div>
            </div>

            <div v-show="activity.show">
                <div class="div-task-container" v-for="task in activity.children" :key="task.id">
                    <div class="div-task">
                        <div class="div-row-text">
                            <div>{{ task.designation }}</div>
                            <strong>|</strong>
                            <div>Gap [min.]: {{ task.gap }}% [{{ task.min_gap }}%]</div>
                            <i v-show="task.children.length > 0" @click="ToggleSkills(task)" class="fas cursor-pointer"
                                :class="{ 'fa-chevron-down': !task.showChildren, 'fa-chevron-up': task.showChildren }"></i>
                        </div>

                        <div class="div-training-requests">
                            <input :id="`training-task-${task.id}`" class="input-training-request" type="text"
                                maxlength="255" v-if="task.showTrainingReqInput">

                            <i class="fa fa-check cursor-pointer" v-if="task.showTrainingReqInput"
                                @click="SendTrainingRequest(`training-task-${task.id}`, task)"></i>

                            <i class="fas fa-pencil-alt cursor-pointer show-icon" v-if="!task.showTrainingReqInput"
                                @click="task.showTrainingReqInput = !task.showTrainingReqInput"></i>

                            <i class="fas fa-xmark cursor-pointer" v-if="task.showTrainingReqInput"
                                @click="task.showTrainingReqInput = !task.showTrainingReqInput"></i>
                        </div>
                    </div>

                    <div class="div-skills-container" v-show="task.show">
                        <div v-for="skill in task.children" :key="skill.id">
                            <div class="div-skill">
                                <div class="div-row-text">
                                    {{ skill.designation }}
                                </div>

                                <div class="div-training-requests">
                                    <input :id="`training-skill-${skill.id}`" class="input-training-request" type="text"
                                        maxlength="255" v-if="skill.showTrainingReqInput">
                                    <i class="fa fa-check cursor-pointer" v-if="skill.showTrainingReqInput"
                                        @click="SendTrainingRequest(`training-skill-${skill.id}`, skill)"></i>

                                    <i class="fas fa-pencil-alt cursor-pointer show-icon" v-if="!skill.showTrainingReqInput"
                                        @click="skill.showTrainingReqInput = !skill.showTrainingReqInput"></i>

                                    <i class="fas fa-xmark cursor-pointer show-icon" v-if="skill.showTrainingReqInput"
                                        @click="skill.showTrainingReqInput = !skill.showTrainingReqInput"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<style scoped>
.div-job-activity-name {
    margin: 10px 0;
    font-weight: bold;
}

.div-activity {
    background-color: var(--default-app-alt-line-color);
}

.div-extra-job {
    background-color: #357ef5;
}

.div-activity,
.div-extra-job {
    margin-top: 10px;
}

.div-task,
.div-activity,
.div-extra-job,
.div-skill {
    padding: 5px;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
}

.div-skill {
    background-color: var(--default-skill-color);
    padding: 5px;
    align-items: center;
}

.div-task-container {
    margin-left: 10px;
}

.div-skills-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.div-skill {
    margin-left: 15px;
}

.div-row-text {
    display: flex;
    align-items: center;
    gap: 5px;
}

.div-training-requests {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-training-request {
    padding: 4px 3px;
}

.div-training-requests i {
    margin-left: 10px;
    transform: scale(1.2);
}

.div-training-requests i:hover {
    color: var(--default-app-hover-color)
}
</style>