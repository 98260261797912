<script setup>
import { KNOLIA } from "@/knolia"
import { Gems } from "@/gems"
import { ref } from "@vue/runtime-core"
import ImportUploadFile from "../components/ImportUploadFile.vue"

const lastWarningGroup = ref(null);


async function Import(type) {
  const fileInput = document.getElementById(type)
  const file = document.getElementById(type).files[0]

  if (!file) {
    fileInput.click()
    return
  }

  let formData = new FormData()

  formData.append("file", file)
  formData.append("type", type)

  if(document.getElementById(`create-${type}-children`)?.checked)
    formData.append("children", 1)
  else
    formData.append("children", 0)

  const response = await KNOLIA.CallBackEnd("post", "import", formData);
  if (response.data.error) {
    Gems.Telegram(response.data.message, "tele-not-allowed")
    if (response.data.excel)
      Gems.ExportExcel(response.data.data)

    return
  }
  Gems.Telegram(response.data.message, "tele-success")

  document.getElementById(type).value = null
}

async function Export(type, table = null) {
  const res = await KNOLIA.CallBackEnd("post", "export", {
    type,
    table,
  })
  Gems.ExportExcel(res.data)
}

function ShowWarning(text, id) {
  const divWarning = document.getElementById(id)

  if (divWarning.children.length > 1) {
    window.CloseWarning(id)
  } else {
    const div = document.createElement("div")
    divWarning.appendChild(div)

    div.innerHTML += `<button class='fas fa-times close-import' onclick='CloseWarning(${id})'></button>`;
    div.innerHTML += '<li>Apenas importe ficheiros que foram exportados deste interface.</li>'
    div.innerHTML += '<li>Não apague colunas do EXCEL exportado.</li>'
    div.innerHTML += '<li>Os registos serão processados pela ordem em que aparecem no ficheiro.</li>'
    div.innerHTML += '<li>Em nenhuma circunstância modifique a coluna "Key" [coluna A].</li>'
    div.innerHTML += '<li>Dado nulo (vazio) na  [Coluna D] será interpretado como "0" (zero).</li>'
    div.innerHTML += '<li>Os dados NÃO deverão conter o caracter ";" (ponto e vírgula).</li>'
    div.innerHTML += text;
    div.innerHTML += '<li>Grave o ficheiro com o seguinte formato:</li>'
    div.innerHTML += "<div class='excel-img'>"
    div.setAttribute("class", "div-import-help")
  }
}

window.CloseWarning = (id) => {
  document
    .getElementById(id)
    .removeChild(document.getElementById(id).lastElementChild)

  lastWarningGroup.value.classList.remove('last-warning-opened')
}

</script>
<template>
  <div class="page-container">

    <div class="container-info-circle">
      <div class="info-circle field-unique"></div>
      <p class="info-text">
        Campos [obrigatórios] de dados únicos
      </p>
    </div>


    <div class="container-info-circle">
      <div class="info-circle field-not-modify"></div>
      <p class="info-text">
        Campos Key de dados únicos que não podem ser modificados depois de carregados na base de dados
      </p>
    </div>
      
    <div class="group">
      <div id="1" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon" @click="ShowWarning(`<br/>
            <li>Novo registo:</li>
            <ul>
              <li>[coluna A] precisa estar vazia.</li>
              <li>O valor da [coluna B] não existe na Base de Dados.</li>
            </ul>
            <li>Alteração [colunas B, C]:</li>
            <ul>
              <li>[coluna A] vazia e valor da [coluna B] existe na Base de Dados (Altera C).</li>
              <li>[coluna A] não vazia (Altera B e C).</li>
            </ul>
            <br>`, 1)
            "></i>
          <span>Regras para importação</span>
        </div>
      </div>

      <button @click="Export(1, 'skills')" class="button">
        EXPORTAR COMPETÊNCIAS
      </button>
      <div class="parent-table">
        <table class="ex-table">
          <thead>
            <tr>
              <th class="field-not-modify">A. Key</th>
              <th class="field-unique">B. Código da competência</th>
              <th>C. Descritor</th>
              <th>D. Inativo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: 1</td>
              <td>Ex.: 15003</td>
              <td>Ex.: Coordenação</td>
              <td>Ex.: 0</td>
            </tr>
            <tr>
              <td>(campo numérico)</td>
              <td>(campo numérico)</td>
              <td>(campo alfa-numérico)</td>
              <td>(campo binário - 0 ou 1)</td>
            </tr>
          </tbody>
        </table>
        
        <div class="div-children">
          <div>
            <input type="radio" name="skills-children" class="cursor-pointer" id="create-skills-children">
            <label for="">Automaticamente criar competências operativas</label>
          </div>

          <div>
            <input type="radio" name="skills-children" class="cursor-pointer" checked>
            <label for="">Não criar competências operativas</label>
          </div>
        </div>
      </div>
      <div class="upload-file parent-upload-file">
        <ImportUploadFile :table-name="'skills'" @import="Import($event)" />
      </div>

      <button @click="Export(2, 'skillsOp')" class="button">
        EXPORTAR COMPETÊNCIAS OPERATIVAS
      </button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="field-not-modify">A. Key</th>
            <th class="field-unique">B. Código da competência operativa</th>
            <th>C. Código da competência</th>
            <th>D. Descritor</th>
            <th>E. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: T1003</td>
            <td>Ex.: 15003</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 0</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo binário - 0 ou 1)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :table-name="'skillsOp'" @import="Import($event)" />
      </div>

      <button @click="Export(3, 'activities')" class="button">
        EXPORTAR ATIVIDADES
      </button>

      <div class="parent-table">
        <table class="ex-table">
        <thead>
          <tr>
            <th class="field-not-modify">A. Key</th>
            <th class="field-unique">B. Código da atividade</th>
            <th>C. Descritor</th>
            <th>D. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: 14000</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 0</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo binário - 0 ou 1)</td>
          </tr>
        </tbody>
      </table>
        
        <div class="div-children">
          <div>
            <input type="radio" name="activities-children" class="cursor-pointer" id="create-activities-children">
            <label for="">Automaticamente criar tarefas</label>
          </div>

          <div>
            <input type="radio" name="activities-children" class="cursor-pointer" checked>
            <label for="">Não criar tarefas</label>
          </div>
        </div>
      </div>

      <div class="upload-file parent-upload-file">
        <ImportUploadFile :table-name="'activities'" @import="Import($event)" />
      </div>

      <button @click="Export(4, 'tasks')" class="button">
        EXPORTAR TAREFAS
      </button>
      <table class="ex-table">
        <thead>
          <tr>
            <th class="field-not-modify">A. Key</th>
            <th class="field-unique">B. Código da tarefa</th>
            <th>C. Código da atividade</th>
            <th>D. Descritor</th>
            <th>E. Inativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: T1400</td>
            <td>Ex.: 14000</td>
            <td>Ex.: Coordenação</td>
            <td>Ex.: 0</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo alfa-numérico)</td>
            <td>(campo binário - 0 ou 1)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :table-name="'tasks'" @import="Import($event)" />
      </div>
    </div>

    <!-- <div class="group" ref="lastWarningGroup">
      <div id="3" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon" @click='
            lastWarningGroup.classList.toggle("last-warning-opened");
            ShowWarning(`<li>Para novos registos, deixe a [coluna A] "vazia".</li>`, 3)
            '></i>
          <span>Regras para importação</span>
        </div>
      </div>

      

      
    </div> -->

    <!-- <div class="group half" ref="lastWarningGroup">
      <div id="3" class="warning">
        <div class="icon-container">
          <i class="fas fa-info-circle icon" @click="
            lastWarningGroup.classList.toggle('last-warning-opened');
            ShowWarning(`<li>A tabela inteira será substituída. Todos os registos que não existirem no ficheiro carregado serão perdidos.</li>`, 3)
            "></i>
          <span>Regras para importação</span>
        </div>
      </div>

      <button @click="Export(8)" class="button">EXPORTAR CONSTITUIÇÃO DAS EQUIPAS</button>
      <table class="ex-table">
        <thead>
          <tr>
            <th>1. Código da equipa</th>
            <th>2. N° Interno</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ex.: 1</td>
            <td>Ex.: A020</td>
          </tr>
          <tr>
            <td>(campo numérico)</td>
            <td>(campo alfa-numérico)</td>
          </tr>
        </tbody>
      </table>
      <div class="upload-file">
        <ImportUploadFile :table-name="'teams'" @import="Import($event)" />
      </div>
    </div> -->
  </div>
</template>

<style>

.blink-import {
  animation: blink-import 1s step-start infinite;
}

@keyframes blink-import {
  50% {
    background-color: var(--default-font-color);
    color: #2361C2;
  }
}

.container-info-circle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.info-text {
  font-size: .9em;
  margin: 0px 5px;
  font-weight: 300;
}

.field-not-modify {
  background-color: #FF4500;
}

.field-unique {
  background-color: rgba(24, 232, 255, 0.7);
}

.div-import-help {
  font-size: 0.8em;
  background-color: var(--default-modal-color);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0px;
  left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 650px;
  min-height: 20px;
  max-width: 610px;
  min-width: 290px;
  z-index: 10;
}

.close-import {
  position: absolute;
  top: 1px;
  right: 15px;
  font-size: 1.3em !important;
  cursor: pointer;
  border: none !important;
  width: 10px !important;
  height: 10px !important;
  background-color: transparent;
}

.close-import:hover {
  color: var(--default-app-hover-color) !important;
  position: absolute;
  background-color: transparent !important;
}

.excel-img {
  display: block;
  height: 380px;
  width: 612px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/excel.png");
}

.last-warning-opened {
  margin-bottom: 70px !important;
}

.parent-upload-file .div-inputs {
  width: 77% !important;
}

.parent-upload-file .btn-import {
  width: 22.5% !important;
}

</style>

<style scoped>

.upload-file {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: center;
}

.ex-table {
  width: 100%;
  height: 100px;
  text-align: center;
  word-wrap: break-word;
  border: 1px solid #1a3575;
  border-collapse: separate;
  border-left: 0;
  border-radius: 25px !important;
  border-spacing: 0px;
  background-color: #3bc4ff18;
}

.ex-table thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}

.ex-table thead tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.ex-table th,
.ex-table tbody td {
  padding: 5px 4px 6px 4px;
  text-align: left;
  vertical-align: top;
  border-left: 1px solid;
}

.ex-table tbody td {
  border-top: 1px solid;
}

.ex-table thead:first-child tr:first-child th:first-child,
.ex-table tbody:first-child tr:first-child td:first-child {
  border-radius: 25px 0 0 0;
}

table.ex-table thead:last-child tr:last-child th:first-child,
table.ex-table tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 25px;
}

/* .half {
  width: 50% !important;
} */

.ex-table td,
.ex-table th {
  padding: 3px 2px;
}

.ex-table thead th,
.ex-table tbody td {
  font-size: 12px;
  text-align: center;
  border-color: #1a3575;
  vertical-align: middle;
}

.ex-table thead th {
  font-weight: bold;
}

.parent-table {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  width: 100%;
}

.parent-table table {
  width: 77%;
}

.div-children {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23%;
}

.div-children div {
  display: flex;
  align-items: self-start;
}

.button {
  width: 100%;
  margin-bottom: 2px;
  font-size: 13px;
}

.button:not(:first-of-type) {
  margin-top: 30px;
}

.button:first-of-type {
  margin-top: 10px;
}

.group {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.warning {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-container>span {
  font-weight: bold;
}

.icon {
  font-size: 1.3em;
  cursor: pointer;
  margin-right: 5px;
}
</style>
